body {
    padding-bottom: 120px;

    background: url('../public/images/app-bg.png') no-repeat center center;
    background-size: cover;
    background-color: #fbf5f2;
    background-attachment: fixed;

    min-height: 100vh;
}

@font-face {
    font-family: 'Hamberger';
    src: url('../public/fonts/HambergerBold.woff2') format('woff2'),
    url('../public/fonts/HambergerBold.woff') format('woff'),
    url('../public/fonts/HambergerBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont ExtraLight DEMO';
    src: url('../public/fonts/Mont-ExtraLightDEMO.woff2') format('woff2'),
    url('../public/fonts/Mont-ExtraLightDEMO.woff') format('woff'),
    url('../public/fonts/Mont-ExtraLightDEMO.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Mont Heavy DEMO';
    src: url('../public/fonts/Mont-HeavyDEMO.woff2') format('woff2'),
    url('../public/fonts/Mont-HeavyDEMO.woff') format('woff'),
    url('../public/fonts/Mont-HeavyDEMO.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

* {
    box-sizing: border-box;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.mainNav {
    list-style: none;
}

.mainWrapper {
    text-align: center;
}

.container {

    display: flex;
    justify-content: center;
}

.text-center {
    text-align: center;
}

#boxes {
    min-height: 200px;
    margin-top: 20px;
}

#boxes img {
    max-width: 120px;
    height: auto;
}

#boxes .box {
    margin-top: 20px;
    float: left;
    text-align: center;
    width: 30%;
    padding: 10px;
}

#boxes .fab {
    padding: 0 5px;
}


.sidebar {
    margin: 0;
    position: fixed;
    width: 100%;
    overflow: auto;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    background-image: linear-gradient(to right, #e1e4fd, #fce9d7);
    display: flex;
    justify-content: space-between;
    padding: 15px 30px;
    z-index: 99;

    background: #2a6834;
}

/* Sidebar links */

.go-to-home a,
.sidebar a {
    display: block;
    color: inherit;
    padding: 0;
    text-decoration: none;
}


.go-to-home a img,
.sidebar a img {
    max-width: 40px;
    height: auto;
}

.go-to-home a img {
    display: block;
    width: 40px;
}

/* Page content. The value of the margin-left property should match the value of the sidebar's width property */
div.content {
    padding: 1px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - 140px);
    width: 100%;
}


div.content h2 {
    font-size: 150px;
}

.go-to-home a,
.sidebar a {
    width: 80px;
    height: 80px;
    padding: 10px 10px 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.sidebar a[href*="words"] {
    padding-bottom: 15px;
}

.sidebar a span:nth-child(2) {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 22px;
}

.sidebar a.active[href*="settings"] img {
    filter: invert(1) brightness(200%) grayscale(1);
}


/* On screens that are less than 700px wide, make the sidebar into a topbar */
@media screen and (max-width: 700px) {
    .sidebar {
        width: 100%;
        height: auto;
        position: relative;
    }

    .sidebar a {
        float: left;
    }

    div.content {
        margin-left: 0;
    }
}

/* On screens that are less than 400px, display the bar vertically, instead of horizontally */
@media screen and (max-width: 400px) {
    .sidebar a {
        text-align: center;
        float: none;
    }
}

.quiz-slider .slick-disabled.slick-arrow {
    opacity: 0 !important;
    visibility: hidden !important;
}

.results-message,
.quiz-slider {
    text-align: center;
    width: 100%;
    padding: 100px 50px 40px;
    background: url('../public/images/learn-panel-bg.png') no-repeat center center;
    background-size: 100% calc(100% - 100px);
    max-width: 968px;
    margin: 50px auto 50px;
    border-radius: 20px;
    position: relative;
    height: clamp(400px, calc(100vh - 350px), 800px);
}

.quiz-slider .slick-slide {
    position: relative;
    padding-bottom: 50px;

}

.results-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-position: center top;
    padding-top: 50px;
    padding-bottom: 200px;
    margin-top: 130px;
    position: relative;
    min-height: 650px;
}

.quiz-slider h3 {
    font-size: 40px;
}

.quiz-slider .slick-arrow {
    width: 80px;
    height: 80px;
    background-color: transparent;
    padding: 0;
    position: absolute;
    top: 50%;
    margin-top: -40px;
    cursor: pointer;
    border: none;
    background-image: url('../public/images/alphabet-slide-arrow.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    text-indent: -999em;
    overflow: hidden;
}

.quiz-slider .slick-arrow.slick-prev {
    left: -140px;
    transform: rotate(180deg);
}

.quiz-slider .slick-arrow.slick-next {
    right: -140px;
}

.quiz-slider .quiz-slide-heading {
    font-size: 120px;
    font-size: clamp(80px, 80vh, 300px);
    line-height: 1;
    margin: 0 0 20px 0;
    font-family: 'Mont Heavy DEMO', sans-serif;
    text-shadow: 2px 4px #2b6935;
    -webkit-text-stroke: 2px #2b6935;
    color: #acd037
}

.quiz-slider .quiz-slide-heading[style] {
    background: #000;
    -webkit-background-clip: text !important;
    /*-webkit-text-fill-color: transparent;*/
}

.quiz-slider .slick-list,
.quiz-slider .slick-track {
    min-height: 100%;
    display: flex;
}

.quiz-slider {
    height: clamp(700px, calc(100vh - 450px), 700px);
}

.quiz-slider .slick-track .slick-slide {
    padding-bottom: 50px;
    height: clamp(600px, calc(100vh - 450px), 600px);
    max-height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.slide-action {
    display: inline-flex;
    justify-content: center;
    cursor: pointer;
    border-radius: 20px;
    line-height: 1;
    width: 100px;
    height: 100px;
    margin: 0 10px;
}

h4 {
    font-size: 18px;
}

.slide-action ~ .blue-btn {
    margin-bottom: 5px;
    font-size: 22px;
}

.slide-action img {
    width: 100%;
    height: auto;
    display: block;
}

.answer-options {
    margin: 30px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.answer-options input[type="radio"] {
    width: 0;
    height: 0;
    opacity: 0;
}

.answer-options label {
    position: relative;
    cursor: pointer;
    margin: 0 5px 10px;
    border: 2px solid #2b6935;
    border-radius: 8px;
    padding: 10px 14px 10px 34px;
    font-weight: bold;
    font-size: 40px;
    background: #add136;
    color: #eff1b4;
    font-family: 'Mont Heavy DEMO', sans-serif;
    white-space: nowrap;
}

.answer-options label span:before {
    content: "";
    position: absolute;
    left: 5px;
    width: 26px;
    height: 26px;
    border: 2px solid #2b6935;
    border-radius: 4px;
    top: 50%;
    margin-top: -15px;
}

.answer-options input[type="radio"]:checked + span:before {
    background-color: #515ede;
}

.answer-options label span:after {
    content: "";
    position: absolute;
    width: 24px;
    height: 24px;
    left: 8px;
    top: 54%;
    margin-top: -15px;
    background: url('../public/images/tick.svg') no-repeat center center;
    background-size: contain;
    opacity: 0;
    transition: opacity 0.2s;
}

.quiz-slide-show-answer .answer-options.has-wrong-answer label input:checked + span:before {
    background-color: #bc1f36;
    border-color: #bc1f36;
}

.quiz-slide-show-answer .answer-options.has-wrong-answer label span:after {
    background: url('../public/images/wrong-answer-icon.svg') no-repeat center center;
    background-size: contain;
}

/* Show the indicator dot when radio button is checked */
.answer-options input[type="radio"]:checked + span:after {
    opacity: 1;
}

.slide-actions {
    padding-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
}

.wrong-answer button,
.success-answer button,
.check-answer-btn {
    position: absolute;
    bottom: 22px;
    left: 50%;
    transform: translateX(-50%);
    background: url('../public/images/check-answer-btn.png') no-repeat center center;
    background-size: 100% 100%;
    color: #fff;
    font-family: 'Hamberger',sans-serif;
    font-weight: bold;
    font-size: 32px;
    padding: 34px 54px;
    border: none;
}

.blue-btn {
    background: #515ede;
    border: none;
    color: #fff;
    border-radius: 6px;
    padding: 10px 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: 3px 3px 0 #6c78f1;
    cursor: pointer;
}

.wrong-answer button,
.success-answer button {
    border: none;
    box-shadow: none;
    background: url('../public/images/green-btn.svg') no-repeat center center;
    background-size: 100% 100%;
    padding: 34px 80px;
    cursor: pointer;
}

.wrong-answer button {
    background: url('../public/images/red-button.svg') no-repeat center center;
    background-size: 100% 100%;
}

.blue-btn img {
    width: 18px;
    height: auto;
    filter: invert(1) brightness(400%) grayscale(1);
    margin-left: 10px;
    transform: rotate(180deg);
}

.light-blue-btn {
    background: #e8eaf9;
    border: none;
    color: #515ede;
    border-radius: 6px;
    padding: 10px 20px;
    display: inline-flex;
    font-size: 22px;
    align-items: center;
    justify-content: center;
    box-shadow: 3px 3px 0 #d6d9ef;
    cursor: pointer;
}

.light-blue-btn img {
    width: 16px;
    height: auto;
    margin: 0 5px 0 0;
}

.wrong-answer img {
    width: 28px;
    margin: -5px 10px 0 0;
}

.red-text,
.wrong-answer {
    color: #f14242;
}

.quiz-slide-single .success-answer,
.quiz-slide-single .wrong-answer {
    margin-bottom: 5px;
}

.green-text {
    color: #42f142;
}

.success-answer .light-blue-btn,
.wrong-answer .light-blue-btn {
    margin-left: 12px;
}

.success-answer .light-blue-btn img {
    margin: -5px 0 0 7px;
}

.reults-message-counters {
    text-align: center;
}

.reults-message-counters span:nth-child(2) {
    font-weight: bold;
}


.congratulations-message {
    text-align: center;
    font-size: 20px;
}

.congratulations-message-title img {
    display: block;
    width: 100%;
    height: auto;
}

.congratulations-stars {
    width: 400px;
    margin: 0 auto;
}

.congratulations-stars img {
    display: block;
    max-width: 100%;
}

.congratulations-message-title {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    top: -70px;
    left: 0;
    right: 0;
}

.congratulations-stars-title {
    font-family: 'Mont Heavy DEMO',sans-serif;
    color: #2a6834;
    font-size: 60px;
    font-weight: bold;
}

body .unsuccessful-message h2,
body .congratulations-message h2 {
    font-size: 48px;
    text-align: center;
    margin: 0;
}

.results-move-back,
.results-move-next {
    padding-top: 30px;
}

.start-test-btn {
    position: absolute;
    top: 50px;
    right: 10px;
    font-size: 24px;
    background: url('../public/images/take-test-btn.png') no-repeat center center;
    background-size: 100% 100%;
    font-family: 'Hamberger',sans-serif;
    font-weight: bold;
    color: #fff;
    border: none;
    padding: 14px 28px;
}

.quiz-slider .slick-dots {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    display: flex !important;
    justify-content: center;
    width: 100%;
    max-width: 500px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 70px;
}

.quiz-slider .slick-dots li {
    display: block;
    width: 100%;
    padding: 0 2px;
}

.quiz-slider .slick-dots li button {
    width: 100%;
    height: 12px;
    background: #2a6834;
    border: none;
    text-indent: -99em;
    overflow: hidden;
    border-radius: 8px;
    pointer-events: none;
}

.quiz-slide-single {
    padding-top: 20px;
}

.quiz-slider .slick-dots li.slick-active button {
    background: #2a6834;
}

.quiz-slider .slick-dots li.slick-active ~ li button {
    background: #2a6834;
    opacity: 0.3;
}

.quiz-slider-index {
    position: absolute;
    right: 160px;
    top: 168px;
    font-weight: 500;
    color: #2a6834;
    font-weight: 800;
    font-size: 20px;
}

.wrong-answer > span,
.success-answer > span,
.quiz-slide-single h4 {
    color: #2a6834;
    font-family: 'Mont Heavy Demo', sans-serif;
    font-size: 28px;
    margin: 20px 0;
}

.wrong-answer > span {
    color: #bc1f36;
}

.home-blocks {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

#boxes .home-blocks .box {
    display: flex;
    min-width: 210px;
    margin: 0 5px -10px;
}

.home-blocks .home-block {
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 8px;
    padding: 15px 20px;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    font-family: 'Mont Heavy DEMO', sans-serif;

    background: url('../public/images/home-btn-bg.svg') no-repeat center center;
    background-size: 100% 100%;
}

.home-blocks .home-block > span:first-child {
    display: flex;
    width: 80px;
    height: 80px;
    padding: 10px 10px 20px;
    align-items: center;
}

.home-blocks .home-block > span:first-child img {
    display: block;
    width: 100%;
    height: auto;
}

.home-blocks .home-block > span:last-child {
    min-width: 90px;
    text-align: left;
}

a.home-block.letters-block {
    color: #d12027;
}

a.home-block.book-block {
    color: #324a8d;
}

#boxes a.home-block.book-block img {
    width: 50px;
}

.home-blocks .settings-block {
    color: #2a6834;
}

#boxes a.home-block.settings-block img {
    width: 50px;
}

#boxes {
    width: 100%;
    min-height: calc(100vh - 200px);
    display: flex;;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.settings-wrapper {
    text-align: center;
    padding: 30px 0;
    margin-top: 40px;
}

.settings-wrapper img {
    max-width: 100px;
    display: block;
    margin: 0 auto 30px;
}

.settings-inner {
    padding: 120px 50px 80px;
    background: url('../public/images/tv-screen.png') no-repeat center center;
    background-size: 100% 100%;
    max-width: 968px;
    height: clamp(700px, calc(100vh - 350px), 900px);
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
}

.settings-sections {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.settings-sections .settings-section {
    border: 3px solid #2b6935;
    border-bottom-width: 5px;
    background-color: #add136;
    padding: 12px;
    width: 100%;
    margin-bottom: 16px;
    border-radius: 12px;
    cursor: pointer;
    color: #2a6834;
    font-size: 28px;
    font-family: 'Mont Heavy Demo', sans-serif;
}

.settings-section h3 {
    margin: 0;
    color: #fff;
    font-size: 50px;
}

.settings-section p {
    margin: 0;
    opacity: 0.6;
    font-size: 20px;
    font-family: 'Mont Heavy Demo', sans-serif;
}

.save-btn {
    cursor: pointer;
}

.form-field {
    margin-bottom: 10px;
}

.form-field label {
    font-weight: 600;
    display: block;
    margin-bottom: 4px;
}

.form-field input[type="text"] {
    width: 100%;
    border: 1px solid #ddd;
    padding: 5px 16px;
    font-weight: normal;
    border-radius: 4px;
    height: 44px;
    font-size: 16px;
    color: inherit;
}

.popup-title {
    margin: 20px 0 16px;
    color: #216834;
    font-size: 50px;
    font-family: 'Hamberger', sans-serif;
    font-weight: bold;
    text-align: center;
}

.current-user-name {
    position: fixed;
    top: 10px;
    right: 10px;
    background: url('../public/images/blue-btn.png') no-repeat center center;
    background-size: 100% 100%;
    padding: 5px 16px;
    font-weight: 600;
    font-size: 18px;
    color: #fff;
    font-family: 'Mont Heavy DEMO',sans-serif;
}

.total-words-added {
    color: #2a6834;
    font-size: 25px;
    font-family: 'Mont Heavy DEMO', sans-serif;
    font-weight: normal;
    text-align: center;
    margin-top: -20px;
}

.current-words-list {
    min-height: 50px;
    margin-bottom: 20px;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.current-words-list .word {
    position: relative;
    border: 2px solid #2b6935;
    background-color: #add136;
    font-family: 'Mont Heavy Demo', sans-serif;
    color: #fff;
    font-size: 18px;
    padding: 6px 16px 6px 10px;
    text-transform: uppercase;
    margin: 0 4px 16px;
    border-radius: 4px;
}

.current-words-list .word span:last-child {

    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(30%, -30%);
    background: #fff;
    cursor: pointer;
    background: url('../public/images/delete-word.svg') no-repeat center center;
    background-size: contain;
}

.no-rewards-message,
.no-words-added {
    text-align: center;
    font-size: 20px;
    margin: 30px;
    width: 100%;
    color: #ff7178;

    font-family: 'Mont Heavy DEMO', sans-serif;
}

.add-word-form {
    display: flex;
    margin-top: auto;
}

.add-word-form input {
    width: 100%;
    border: 1px solid #ddd;
    padding: 5px 10px;
    font-size: 18px;
    outline: none;
}

.add-word-form input + button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.form-field.checkbox-field {
    display: flex;
    justify-content: space-between;
}

.form-field.checkbox-field input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
}

.form-field.checkbox-field input + span {
    display: block;
    width: 40px;
    height: 24px;
    border: 1px solid #ddd;
    border-radius: 24px;
    background: #444;
    position: relative;
}

.form-field.checkbox-field input + span:before {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 6px;
    margin-top: -7px;
    transition: transform 0.25s;
}

.form-field.checkbox-field input:checked + span:before {
    transform: translateX(14px);
}

.form-field.checkbox-field input:checked + span {
    background: #cb0009;
}

.current-backgrounds-list {
    margin-bottom: 40px;
    margin-top: 14px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.custom-background-image {
    width: 31.33%;
    border: 1px solid #ddd;
    min-height: 150px;
    background-size: cover !important;
    background-position: center;
    margin: 16px 0 0;
    position: relative;
    border-radius: 18px;
    margin: 0 1%;
}

.custom-background-image span:last-child {
    border: 1px solid #cb0009;
    color: #cb0009;
    line-height: 1;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: lowercase;
    font-family: 'Arial', sans-serif;
    border-radius: 50%;
    padding-bottom: 2px;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(30%, -30%);
    background: #fff;
    font-size: 12px;
    cursor: pointer;
}

.quiz-time-title,
.settings-page-title {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    transform: translateY(-50%);
}

.quiz-time-title {
    transform: translateY(-70%);
 }

.settings-page-title img {
    width: 100%;
    max-width: 100%;
    margin: 0;
}

.accessibility-popup .popup-inner,
.words-popup .popup-inner,
.add-name-popup .popup-inner {
    background: url('../public/images/name-popup.png') no-repeat center center;
    background-size: 100% 100%;
}

.font-size-select > div[class*="-control"],
.accessibility-popup select,
.add-word-form input,
.popup-inner input[type="text"] {
    background-color: #2a6834;
    border-right: 5px solid #1a3f1d;
    border-top: 5px solid #1a3f1d;
    color: #eff1b4;
    font-size: 28px;
    height: 60px;
    font-family: 'Mont Heavy Demo', sans-serif;
}

.background-management-wrapper .add-word-form input {
    font-size: 20px;
}

.font-size-select div[class*="singleValue"] {
    color: #eff1b4;
}

.font-size-select > div[class*="-control"] {
    height: inherit;
}

.accessibility-popup .accessibility-popup-inner {
    padding: 0 30px 30px;
}

.font-size-select > div[class*="-control"],
.add-word-form input {
    color: #fff;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    border-right: none;
}

.add-word-form input::placeholder {
    color: #eff1b4;
}

.add-word-form input + button {
    left: -3px;
    position: relative;
}

/* add placeholder styling */
input[type="text"]::placeholder {
    color: #eff1b4;
}

.add-word-wrapper {
    padding: 0 30px 30px;
}

.blue-bg-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: url('../public/images/blue-btn.png') no-repeat center center;
    background-size: 100% 100%;
    border: none;
    cursor: pointer;
    color: #fff;
    padding: 14px 30px;
    font-family: 'Hamberger', sans-serif;
    font-weight: bold;
    font-size: 24px;
}

.add-name-popup .form-field {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.sidebar-right,
.sidebar-left {
    display: flex;
}
.go-to-home a,
.sidebar a {
    background: url('../public/images/bar-icon-bg.svg') no-repeat center center;
    background-repeat: no-repeat;
}

.sidebar a.active {
    background: url('../public/images/bar-icon-active-bg.svg') no-repeat center center;
    background-repeat: no-repeat;
}

.sidebar a:not(:last-child) {
    margin-right: 20px;
}

.sidebar a img {
    width: 60px;
    height: auto;
}

.quizSliderWrapper {
    position: relative;
}

.background-management-wrapper {
    padding: 0 30px 30px;
}

.rewards-btn {
    width: 80px;
    display: block;
    position: absolute;
    top: 20px;
    left: 30px;
}

.rewards-btn img {
    display: block;
    width: 100%;
    height: auto;
}

.results-move-next {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    align-items: center;
    transform: translateY(-60%);
}

.home-btn {
    display: inline-block;
    width: 90px;
    background: url('../public/images/bar-icon-bg.svg') no-repeat center center;
    background-repeat: no-repeat;
    padding: 16px 15px 20px;
    margin-right: 30px;
}

.results-move-next .next-btn {
    width: 90px;
    background: none;
    border: none;
    padding: 0;
}

.rewards-icon-wrapper {
    width: 500px;
    margin: 0 auto;
}

.rewards-icon-wrapper img {
    display: block;
    width: 100%;
}

.results-message.show-reward {
    background: url('../public/images/tv-screen.png') no-repeat center top;
    background-size: 100% 100%;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rewards-close {
    position: absolute;
    right: 0;
    top: 0;
    width: 80px;
    cursor: pointer;
}

.rewards-list-wrapper {

    max-height: calc(100vh - 280px);
    overflow-y: auto;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.rewards-item {
    width: 120px;
    margin: 0 10px 14px;
}

.rewards-item img {
    display: block;
    width: auto;
    height: auto;
    max-width: initial;
    max-height: 70px;
    margin: 0 auto;
}

.no-more-questions {
    background-color: #f8f6b4;
    background-image: none;
    max-width: 94vw;
    height: 320px;
    padding-top: 40px;
    padding-bottom: 40px;
    border-radius: 36px / 24px;
    border: 5px solid #2b6934;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: 'Mont Heavy DEMO',sans-serif;
    color: #2b6934;
    font-size: 28px;
    padding: 25px 25px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 860px;
}

.no-more-questions .go-to-home {
    margin-top: 10px;
    cursor: pointer;
}

.rewards-icon-wrapper {
    position: relative;
}

.earned-reward-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: reward 5s infinite linear alternate;
}

.slide-action.slide-icon-speaker.quiz-play-icon {
    position: relative;
}

.slide-action.slide-icon-speaker.quiz-play-icon:before {
    content: 'Listen';
    display: block;
    position: absolute;
    right: 100%;
    transform: translateX(-50px) rotate(-12deg);
    font-size: 22px;
    font-family: 'Mont Heavy DEMO';
    color: #286833;
    animation: listen 1s infinite linear alternate;
}

.slide-action.slide-icon-speaker.quiz-play-icon:after {
    content: '';
    display: block;
    width: 50px;
    height: 18px;
    background: url('../public/images/arrow-right.svg') no-repeat center center;
    background-size: contain;
    position: absolute;
    right: 100%;
    top: 20px;
    transform: translateX(-5px) rotate(12deg);
    animation: slide_arrow 1s infinite linear alternate;
}

.unsuccessful-message img {
    width: 100px;
}

@keyframes listen {
    0% {
        transform: translateX(-50px) scale(0.9) rotate(-12deg);
    }
    100% {
        transform: translateX(-50px) scale(1.1) rotate(-12deg);
    }
}

@keyframes slide_arrow {
    0% {
        transform: translateX(-15px) rotate(12deg);
    }
    100% {
        transform: translateX(-5px) rotate(12deg);
    }
}

@keyframes reward {
    0% {
        transform: translate(-53%, -50%) scale(0.9) rotate(15deg);
    }
    100% {
        transform: translate(-53%, -53%) scale(1.3) rotate(-15deg);
    }
}

@media all and (max-width: 1180px) {
    .quiz-slider .slick-arrow.slick-next {
        right: -80px;
    }
    .quiz-slider .slick-arrow.slick-prev {
        left: -80px;
    }

    .quiz-slider .slick-arrow {
        margin-top: -70px;
    }

}

@media all and (max-width: 1000px) {
    .results-message,
    .quiz-slider {
        max-width: calc(100vw - 250px);
        min-height: calc(100vh - 180px);
        min-height: 500px;
        width: 650px;
    }

    .quiz-slider .slick-arrow.slick-prev {
        left: -100px;
    }

    .quiz-slider .slick-arrow.slick-next {
        right: -100px;
    }

    .quiz-slider .quiz-slide-heading {
        font-size: clamp(80px, 80vh, 100px)
    }

    .slide-action {
        width: 70px;
        height: 70px;
    }

    .wrong-answer button,
    .success-answer button,
    .check-answer-btn {
        padding: 20px;
        bottom: 50px;
        font-size: 28px;
    }

    .slide-actions {
        bottom: 50px;
    }

    .results-message, .quiz-slider {
        max-width: calc(100vw - 140px);
    }

    .quiz-time-title {
        width: 80%;
    }

    .wrong-answer > span,
    .success-answer > span,
    .quiz-slide-single h4 {
        font-size: 20px;
    }

    .answer-options label {
        min-width: 100px;
        margin-bottom: 10px;
        display: block;
        padding: 3px 14px 0px 35px;
    }

    .answer-options {
        flex-wrap: wrap;
    }

    .quiz-slider .slick-dots {
        max-width: 200px;
    }

    .quiz-slider .slick-dots li button {
        height: 6px;
    }

    .success-answer button.light-blue-btn {
        padding: 30px 50px;
        bottom: 40px;
    }

    .congratulations-message-title {
        width: 80%;
        top: -40px;
    }

    .congratulations-stars {
        width: 300px;
    }

    .congratulations-stars-title {
        font-size: 50px;
    }

    .results-move-next .home-btn,
    .results-move-next .next-btn {
        width: 70px;
    }

    .results-move-next {
        bottom: 50px;
    }

    .quiz-slider .quiz-slide-heading {
        font-size: 140px !important;
    }

    .quiz-slider .quiz-slide-heading[data-length="4"] {
        font-size: 130px !important;
    }

    .quiz-slider .quiz-slide-heading[data-length="5"] {
        font-size: 120px !important;
    }

    .quiz-slider .quiz-slide-heading[data-length="6"] {
        font-size: 110px !important;
    }

    .quiz-slider .quiz-slide-heading[data-length="7"] {
        font-size: 100px !important;
    }

    .quiz-slider .quiz-slide-heading[data-length="8"] {
        font-size: 90px !important;
    }

    .quiz-slider .quiz-slide-heading[data-length="9"] {
        font-size: 70px !important;
    }

    .quiz-slider .quiz-slide-heading[data-length="10"] {
        font-size: 60px !important;
    }

    .letter-item .quiz-slide-heading[data-length="10+"] {
        font-size: 50px !important;
    }

    .settings-section h3 {
        font-size: 40px;
    }
}

@media all and (max-width: 767px) {
    .settings-inner,
    .results-message, .quiz-slider {
        background-color: #f8f6b4;
        background-image: none;
        max-width: 100%;
        height: 200px;
        padding-top: 30px;
        padding-bottom: 30px;
        border-radius: 36px / 24px;
        border: 5px solid #2b6934;
        margin: 0;
    }

    .settings-inner {
        height: auto;
    }

    .quiz-slider .slick-arrow {
        width: 50px;
        height: 50px;
        z-index: 1;
        margin-top: 75px;
        top: 100%;
    }



    .quiz-slider .slick-arrow.slick-next {
        right: initial;
    }

    .quiz-slider .slick-arrow.slick-next,
    .quiz-slider .slick-arrow.slick-prev {
        left: 50%;
        left: calc(50% - 50px);
    }

    .quiz-slider .slick-arrow.slick-next {
        left: calc(50% + 20px)
    }

    div.content {
        padding-left: 10px;
        padding-right: 10px;
        min-height: calc(100vh - 200px);
    }

    .quiz-slider .slick-track .slick-slide {
        height: 400px;
        padding: 0;
    }

    .sidebar {
        padding: 5px 10px;
        position: fixed;
        bottom: 0;
    }

    .sidebar a {
        width: 50px;
        height: 50px;
    }

    .sidebar a img {
        width: 30px;
    }

    .rewards-btn {
        width: 40px;
        left: 10px;
        top: 10px;
    }

    .start-test-btn {
        font-size: 18px;
        top: 10px;
        right: 10px;
    }

    body {
        height: 100vh;
        padding: 60px 0 100px;
    }

    .is-letter-slide-item.letter-item .quiz-slide-heading,
    .letter-item .quiz-slide-heading {
        font-size: 180px !important;
    }

    .quiz-slider .quiz-slide-heading {
        text-shadow: 1px 1px #2b6935;
        font-size: 100px !important;
    }

    .quiz-slider .quiz-slide-heading[data-length="4"] {
        font-size: 85px !important;
    }

    .quiz-slider .quiz-slide-heading[data-length="5"] {
        font-size: 80px !important;
    }

    .quiz-slider .quiz-slide-heading[data-length="6"] {
        font-size: 70px !important;
    }

    .quiz-slider .quiz-slide-heading[data-length="7"] {
        font-size: 55px !important;
    }

    .quiz-slider .quiz-slide-heading[data-length="8"] {
        font-size: 50px !important;
    }

    .quiz-slider .quiz-slide-heading[data-length="9"] {
        font-size: 40px !important;
    }

    .quiz-slider .quiz-slide-heading[data-length="10"] {
        font-size: 30px !important;
    }

    .letter-item .quiz-slide-heading[data-length="10+"] {
        font-size: 30px !important;
    }

    .settings-inner {
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 10px;
        min-height: 300px;
    }

    .settings-section h3 {
        font-size: 18px;
    }

    .settings-section p {
        font-size: 11px;
    }

    .settings-page-title {
        width: 240px;
        transform: translateY(-70%);
    }

    h3.popup-title {
        font-size: 26px;
    }

    .total-words-added {
        font-size: 16px;
    }

    .accessibility-popup .popup-inner,
    .words-popup .popup-inner,
    .add-name-popup .popup-inner {
        background: #f8f6b4;
        border: 4px solid #2b6934;
        border-radius: 36px / 24px;
        padding-left: 20px;
        padding-right: 20px;
        width: calc(100vw - 20px);
    }

    .add-word-wrapper {
        padding: 0;
    }

    .font-size-select > div[class*="-control"],
    .accessibility-popup select,
    .add-word-form input,
    .popup-inner input[type="text"] {
        font-size: 16px;
    }

    .popup-inner .close-btn {
        right: -9px;
        top: -4px;
    }

    .accessibility-popup .accessibility-popup-inner,
    .background-management-wrapper {
        padding: 0;
    }

    .custom-background-image {
        height: 80px;
        min-height: 80px;
        border-radius: 6px;
    }

    .background-management-wrapper .add-word-form input {
        font-size: 16px;
    }

    .current-words-list {
        max-height: calc(100vh - 300px);
        overflow-y: auto;
        padding-top: 5px;
        padding-bottom: 10px;
    }

    .current-words-list .word {
        font-size: 16px;
    }

    .answer-options > div {
        width: 100%;
    }

    .wrong-answer button,
    .success-answer button,
    .check-answer-btn,
    .success-answer button.light-blue-btn {
        font-size: 25px;
        white-space: nowrap;
        bottom: 2px;
        transform: translate(-50%, 0);
        margin: 0;
    }

    .answer-options label {
        font-size: 28px;
    }

    .quiz-slide-single {
        padding-bottom: 31px;
    }

    .wrong-answer > span, .success-answer > span, .quiz-slide-single h4 {
        font-size: 16px;
    }

    .is-quiz-slider .slick-track .slick-slide {
        justify-content: flex-start;
        height: 500px;
    }

    .is-quiz-slider .slick-dots {
        top: 100%;
        left: 0;
        transform: translateY(50px);
    }

    .quiz-slider.is-quiz-slider {
        height: 570px;
    }

    .quiz-slider-index {
        top: 8px;
        right: 6px;
        font-size: 14px;
        background: #add136;
        border-radius: 7px;
        padding: 5px;
    }

    .wrong-answer button,
    .success-answer button.light-blue-btn {
        padding: 18px 34px;
        font-size: 26px
    }

    .success-answer button.light-blue-btn {
        padding: 20px 45px;
    }

    .light-blue-btn img,.wrong-answer img {
        width: 10px;
    }

    .results-move-next {
        bottom: 0;
        transform: translateY(-30px);
    }

    .congratulations-stars-title {
        font-size: 30px;
    }

    .results-message.show-reward {
        background: #f8f6b4;
    }

    .rewards-icon-wrapper {
        width: 300px;
    }

    .rewards-close {
        width: 40px;
    }

    .rewards-item img {
        max-height: 50px;
        max-width: 50px;
    }

    .rewards-item {
        width: 60px;
    }

    .quiz-slider.letters-quiz-slider {
        padding-left: 20px;
        padding-right: 20px;
    }

    .results-message, .quiz-slider {
        min-height: 450px;
    }

    .quiz-slider .slick-arrow {
        margin-top: 40px;
    }

    .congratulations-message-title {
        top: -20px;
    }

    .current-user-name {
        font-size: 15px;
        padding: 3px 10px;
        top: 16px;
    }

    .slide-action.slide-icon-speaker.quiz-play-icon:before {
        right: 80%;
    }
}

@media all and (max-width: 380px) {
    .congratulations-stars {
        width: 200px;
    }
}
@media all and (max-height: 640px) {
    .quiz-slider .slick-arrow {
        width: 30px;
        height: 30px;
    }

    .quiz-slider .slick-arrow.slick-next {
        margin-top: 0;
        top: -80px;
    }
    .quiz-slider .slick-arrow.slick-prev {
        margin-top: 0;
        top: -80px;
    }
    .quiz-slider .slick-arrow.slick-prev {
        left: calc(50% - 30px);
    }

    .quiz-slider .slick-arrow.slick-next {
        left: calc(50% + 20px);
    }

    .start-test-btn {
        top: initial;
        bottom: 106px;
        z-index: 9;
        left: 50vw;
        right: initial;
        transform: translateX(-49%);
    }
    
}