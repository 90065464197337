.popup{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-inner {
  position: relative;
  width: 100%;
  max-width: 800px;
  padding: 30px;
  border-radius: 10px;
  background-color: white;
}

.popup-inner .close-btn {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  background: none;
  color: #fff;
  border: none;
  width: 50px;
  height: 50px;
  cursor: pointer;
  border-radius: 50%;
}

.popup-inner .popup-image {
	width: 100%;
	height: auto;
}

.popup-inner label {
  color: #2a6834;
  font-family: 'Mont Heavy DEMO',sans-serif;
  font-weight: bold;
  font-size: 20px;
}